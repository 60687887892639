import { Tab } from '@headlessui/react/dist/components/tabs/tabs';
import React, { useState } from 'react';

import { classNames } from '../../../helpers/class-names';
import {
  FacebookUserProfilesExportForm,
  FleetFineTotalsReportForm,
  IndividualFineTotalsReportForm,
  LeadPartnerFineTotalsReportForm,
  TmtCapeTownIdExportForm,
  UnverifiedEmailsReportForm,
  ReferralsExportForm,
  CallTotalsReportForm,
  InvoiceTotalsReportForm,
  UsersPhoneNumbersForm,
} from '../../forms';
import { FinesPerStatementByMunicipalityReportOrganism } from '../fines-per-statement-by-municipality-report-organism/fines-per-statement-by-municipality-report-organism';
import { StatementRunStatementsSentReportOrganism } from '../statement-run-statements-sent-report-organism/statement-run-statements-sent-report-organism';
import { GeneralReportForm } from '../../forms/report-forms/general-report-form/general-report-form';
import { PaymentSourcesReportForm } from '../../forms/report-forms/payment-sources-report-form/payment-sources-report-form';

export enum ReportTypeDescriptionEnum {
  'Unverified Emails' = 0,
  'TMT Cape Town ID Sign Up Export' = 1,
  'Fleet Fine Totals' = 2,
  'Individual Fine Totals' = 3,
  'Lead Partner Fine Totals' = 4,
  'Facebook User Profiles' = 5,
  'Statement - Fines by Municipality' = 6,
  'Statement Run - Statements Created' = 7,
  'Referrals Sign Up Export' = 8,
  'Statistics Totals' = 9,
  'Calls Made' = 10,
  'Invoice Totals' = 11,
  'Payment Totals By Sources' = 12,
  'Users Phone Numbers' = 13,
}

export enum ReportTypeEnum {
  UnverifiedEmails = 0,
  TmtCapeTownIdExport = 1,
  FleetFineTotals = 2,
  IndividualFineTotals = 3,
  LeadPartnerFineTotals = 4,
  FacebookUserExport = 5,
  FinesPerStatementByMunicipality = 6,
  StatementRunStatementsCreated = 7,
  ReferralsSignUpExport = 8,
  GeneralReport = 9,
  CallTotals = 10,
  InvoiceTotals = 11,
  PaymentSources = 12,
  UsersPhoneNumbers = 13,
}

export const ReportsOrganism: React.FC = () => {
  const [reportGroups] = useState([
    {
      name: 'General',
      reports: [
        ReportTypeEnum.UnverifiedEmails,
        ReportTypeEnum.TmtCapeTownIdExport,
        ReportTypeEnum.FacebookUserExport,
        ReportTypeEnum.GeneralReport,
        ReportTypeEnum.UsersPhoneNumbers,
      ],
    },
    {
      name: 'Fines',
      reports: [
        ReportTypeEnum.IndividualFineTotals,
        ReportTypeEnum.FleetFineTotals,
        ReportTypeEnum.LeadPartnerFineTotals,
      ],
    },
    {
      name: 'Invoicing',
      reports: [ReportTypeEnum.PaymentSources],
    },
    {
      name: 'Statements',
      reports: [
        ReportTypeEnum.FinesPerStatementByMunicipality,
        ReportTypeEnum.StatementRunStatementsCreated,
      ],
    },
    {
      name: 'Call Centre',
      reports: [ReportTypeEnum.CallTotals, ReportTypeEnum.InvoiceTotals],
    },
    {
      name: 'Referrals',
      reports: [ReportTypeEnum.ReferralsSignUpExport],
    },
  ]);

  const renderSelectedReport = (report: ReportTypeEnum): JSX.Element => {
    switch (report) {
      case ReportTypeEnum.UnverifiedEmails:
        return <UnverifiedEmailsReportForm />;
      case ReportTypeEnum.TmtCapeTownIdExport:
        return <TmtCapeTownIdExportForm />;
      case ReportTypeEnum.FacebookUserExport:
        return <FacebookUserProfilesExportForm />;
      case ReportTypeEnum.IndividualFineTotals:
        return <IndividualFineTotalsReportForm />;
      case ReportTypeEnum.FleetFineTotals:
        return <FleetFineTotalsReportForm />;
      case ReportTypeEnum.LeadPartnerFineTotals:
        return <LeadPartnerFineTotalsReportForm />;
      case ReportTypeEnum.FinesPerStatementByMunicipality:
        return <FinesPerStatementByMunicipalityReportOrganism />;
      case ReportTypeEnum.StatementRunStatementsCreated:
        return <StatementRunStatementsSentReportOrganism />;
      case ReportTypeEnum.ReferralsSignUpExport:
        return <ReferralsExportForm />;
      case ReportTypeEnum.GeneralReport:
        return <GeneralReportForm />;
      case ReportTypeEnum.CallTotals:
        return <CallTotalsReportForm />;
      case ReportTypeEnum.InvoiceTotals:
        return <InvoiceTotalsReportForm />;
      case ReportTypeEnum.PaymentSources:
        return <PaymentSourcesReportForm />;
      case ReportTypeEnum.UsersPhoneNumbers:
        return <UsersPhoneNumbersForm />;
      default:
        return <></>;
    }
  };

  return (
    <div className="flex-grow">
      <Tab.Group vertical>
        <div className="flex">
          <Tab.List className="flex flex-col space-x-1 rounded-xl bg-blue-900/20 p-1">
            {reportGroups.map((reportGroup) => (
              <div key={crypto.randomUUID()}>
                <span>{reportGroup.name}</span>
                {reportGroup.reports.map((report) => (
                  <Tab
                    key={crypto.randomUUID()}
                    className={({ selected }) =>
                      classNames(
                        'w-full rounded-lg py-2.5 text-sm font-medium leading-5 focus:outline-none',
                        '',
                        selected ? 'bg-white text-blue-800 shadow' : 'text-blue-500',
                      )
                    }
                  >
                    {ReportTypeDescriptionEnum[report]}
                  </Tab>
                ))}
              </div>
            ))}
          </Tab.List>
          <Tab.Panels className="flex-grow">
            {reportGroups
              .flatMap((reportGroup) => reportGroup.reports)
              .map((report) => (
                <Tab.Panel
                  key={crypto.randomUUID()}
                  className={classNames('rounded-xl bg-white p-3', 'focus:outline-none')}
                >
                  {renderSelectedReport(report)}
                </Tab.Panel>
              ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
};
