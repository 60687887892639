import { AxiosResponse } from 'axios';
import * as qs from 'qs';

import authNetworkService from '../auth-network/auth-network.service';
import { UpdateProfileRequest } from '../../../types/profile.types';
import adminProfileUrls from './admin-profiles.urls';
import {
  AccountProfiles,
  FineDetailDto,
  Guid,
  PaginatedList,
  PaginatedProfileFinesQuery,
  ServiceResult,
  SetNoteProps,
} from '../../../types';

const update = (profileId: string, updateRequest: UpdateProfileRequest) => {
  const url = adminProfileUrls.update(profileId);
  return authNetworkService.put(url, updateRequest).catch((error) => {
    return Promise.reject(error);
  });
};

export const getAccountProfiles = (id: string): Promise<AccountProfiles> => {
  const url = adminProfileUrls.FindProfileUrl();
  return authNetworkService
    .get(url, {
      params: {
        userId: id,
      },
    })
    .then((apiResponse: AxiosResponse<ServiceResult<AccountProfiles>>) => {
      apiResponse.data.data.business?.businesses?.forEach((business) =>
        business.links.forEach((link) => {
          link.sync = link.syntellExportSyncActive ? 'Y' : 'N';
        }),
      );
      apiResponse.data.data.individual?.familyMembers?.forEach((familyMember) => {
        familyMember.sync = familyMember.syntellExportSyncActive ? 'Y' : 'N';
      });
      return apiResponse.data.data;
    });
};

const setNote = (setNoteProps: SetNoteProps) => {
  const url = adminProfileUrls.setNoteUrl();
  return authNetworkService.put(url, setNoteProps);
};

const getPaginatedProfileFines = (
  profileId: Guid,
  paginated: PaginatedProfileFinesQuery,
): Promise<PaginatedList<FineDetailDto>> => {
  const url = adminProfileUrls.getProfileFinesPaginated(profileId);
  return authNetworkService
    .get(url, {
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: 'repeat', allowDots: true }),
      params: {
        ...paginated,
      },
    })
    .then((response: AxiosResponse<ServiceResult<PaginatedList<FineDetailDto>>>) => {
      return response.data.data;
    });
};

const exportProfileFinesToCsv = (
  profileId: Guid,
  isHuman: boolean,
  paid: boolean,
): Promise<AxiosResponse<Blob>> => {
  const url = adminProfileUrls.exportProfileFinesToCsvUrl();
  return authNetworkService.get(url, {
    params: {
      profileId,
      isHuman,
      isPaid: paid,
    },
  });
};

export default {
  update,
  setProfileNote: setNote,
  getPaginatedProfileFines,
  exportProfileFinesToCsv,
};
