import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import DateTimePicker from 'react-datetime-picker';
import { Checkbox } from '@material-ui/core';
import toast from 'react-hot-toast';

import { ReportFilterProps } from '../../../../types';
import { Button } from '../../../atoms';
import { useDownloadFile } from '../../../../hooks/global';
import userService from '../../../../services/api/user/user.service';
import { UsersPhoneNumbersSchema } from './schemas';

export const UsersPhoneNumbersForm: React.FC = () => {
  const initialValues: ReportFilterProps = {
    fromDate: null,
    toDate: null,
    verified: false,
    viewAll: false,
    filterDateBy: 'CreatedDate',
  };

  const { download, loading } = useDownloadFile({
    apiDefinition: async (params: ReportFilterProps) => userService.getUsersPhoneNumbers(params),
    onSuccess: () => toast.success('File downloaded successfully!'),
    onError: () => toast.error('Error downloading the file.'),
  });

  const handleSubmission = async (
    values: ReportFilterProps,
    { setSubmitting }: FormikHelpers<ReportFilterProps>,
  ) => {
    try {
      await download(values);
    } catch (error) {
      toast.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
      onSubmit={handleSubmission}
      validationSchema={UsersPhoneNumbersSchema}
    >
      {({
        handleSubmit,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        handleReset,
        setFieldValue,
        errors,
        touched,
      }) => {
        const _setFromDate = (e) => {
          setFieldValue('fromDate', e);
        };
        const _setToDate = (e) => {
          setFieldValue('toDate', e);
        };

        return (
          <form
            onReset={handleReset}
            onSubmit={handleSubmit}
            className="flex flex-col justify-start items-start bg-gray-100 shadow-lg p-4 gap-4"
          >
            <span className="w-full text-xl mb-2">Download Users Phone Numbers Report</span>
            <label htmlFor="filter-date-from" className="w-4/5 flex flex-col ">
              Filter From
              <DateTimePicker
                id="filter-date-from"
                clearIcon={null}
                format="y-MM-dd"
                onChange={_setFromDate}
                value={values.fromDate}
                disableClock
              />
              {touched.toDate && errors.toDate && (
                <span className="text-red-500 text-sm mt-1">{errors.fromDate}</span>
              )}
            </label>
            <label htmlFor="filter-date-to" className="w-4/5 flex flex-col">
              Filter To
              <DateTimePicker
                id="filter-date-to"
                clearIcon={null}
                format="y-MM-dd"
                onChange={_setToDate}
                value={values.toDate}
                disableClock
              />
              {touched.fromDate && errors.fromDate && (
                <span className="text-red-500 text-sm mt-1">{errors.toDate}</span>
              )}
            </label>
            <div className="flex flex-row w-4/5">
              <label htmlFor="verified" className="w-4/5 flex flex-col">
                Verified
                <Checkbox
                  checked={values.verified}
                  name="verified"
                  id="verified"
                  color="secondary"
                  onChange={handleChange('verified')}
                  onBlur={handleBlur('verified')}
                  disableRipple
                  disabled={values.viewAll}
                />
              </label>
              <label htmlFor="viewAll" className="w-4/5 flex flex-col">
                View All
                <Checkbox
                  checked={values.viewAll}
                  name="viewAll"
                  id="viewAll"
                  color="secondary"
                  onChange={handleChange('viewAll')}
                  onBlur={handleBlur('viewAll')}
                  disableRipple
                />
              </label>
            </div>
            <Button
              isLoading={isSubmitting || loading}
              onClick={handleSubmit}
              className="bg-tertiary hover:bg-tertiary-dark text-white text-base self-center w-auto"
            >
              Download
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
