import { AxiosResponse } from 'axios';
import * as qs from 'qs';

import { ServiceResult, NextCallDto, Guid, DailyCallDto } from '../../../types';
import authNetworkService from '../auth-network/auth-network.service';
import adminDailyCallsUrls from './admin-daily-calls.urls';

const getById = async (id: Guid): Promise<NextCallDto> => {
  const url = `${adminDailyCallsUrls.dailyCallsUrl()}/${id}`;
  const response = await authNetworkService.get<ServiceResult<DailyCallDto>>(url);
  return response.data.data;
};

const getAgentNextCall = async (): Promise<NextCallDto> => {
  const url = adminDailyCallsUrls.dailyCallsUrl();
  const response = await authNetworkService.get<ServiceResult<NextCallDto[]>>(url);
  return response.data.data[0];
};

export const getAllDailyCallsContent = (
  callCentreAgentId: string = null,
): Promise<AxiosResponse<Blob>> => {
  const url = adminDailyCallsUrls.getBaseContentUrl();

  return authNetworkService.get(url, {
    responseType: 'blob',
    timeout: 0,
    headers: { Accept: 'application/csv' },
    params: {
      callCentreAgentId,
    },
    paramsSerializer: (params) => qs.stringify(params, { allowDots: true }),
  });
};

const repopulateDailyCalls = () => {
  const url = adminDailyCallsUrls.repopulateDailyCallsUrl();

  return authNetworkService.post(url, null, { timeout: 0 }).catch((error) => {
    return Promise.reject(error);
  });
};

const removeFromCallList = (userId: string) => {
  const url = adminDailyCallsUrls.doNotContact();

  return authNetworkService
    .post(url, { UserId: userId }, { timeout: 0 })
    .catch((error) => Promise.reject(error));
};

const callContactDoNotContactCheck = async (userId: string) => {
  const url = `${adminDailyCallsUrls.callContactDoNotContactCheck()}/${userId}`;

  const response = await authNetworkService.get<ServiceResult<boolean>>(url);

  return response.data.data;
};

export default {
  getById,
  getAgentNextCall,
  getAllDailyCallsContent,
  repopulateDailyCalls,
  removeFromCallList,
  callContactDoNotContactCheck,
};
