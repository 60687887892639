import { useQuery } from 'react-query';

import adminDailyCallsService from '../../services/api/admin-daily-calls/admin-daily-calls.service';
import { Guid } from '../../types';

export const useCallContactCheckStatus = (userId: Guid) => {
  return useQuery<boolean, Error>(
    ['CallContactDoNotContactCheck', userId],
    () => adminDailyCallsService.callContactDoNotContactCheck(userId),
    {
      enabled: !!userId,
    },
  );
};
