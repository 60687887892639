import config from '../../../config';
import { BlackListFilterOptions } from '../../../types';

const baseUrl = `${config.apiUrl()}/BackOffice/BlackList`;

export default {
  getBaseUrl: (): string => `${baseUrl}`,
  deleteUrl: (userId: string) => `${baseUrl}/${userId}`,
  getPaginatedBlackListUrl: (filterOptions: BlackListFilterOptions) =>
    `${baseUrl}?PageNumber=${filterOptions.pageNum}&PageSize=${filterOptions.pageEntries}&UserId=${filterOptions.userId}&FullName=${filterOptions.fullName}`,
};
